import { Injectable, inject } from '@angular/core';
import { Route, Router, UrlTree } from '@angular/router';
import { FeatureFlagService } from '../../shared/services/feature-flag.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagLoadingGuard {
  private router = inject(Router);
  private featureFlags = inject(FeatureFlagService);

  canLoad(route: Route): boolean | UrlTree {
    if (!route.data?.featureFlag) return false;
    return this.featureFlags.isFeatureEnabled(route.data.featureFlag) ? true : this.router.createUrlTree(['/artworks']);
  }
}

import { TranslateLoader } from '@ngx-translate/core';
import { set, trim } from 'lodash-es';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class SSVTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<{ [key: string]: string }> {
    return from(fetch(`/assets/i18n/${lang}.csv`).then((response) => response.text())).pipe(
      map((fileContent: string) =>
        fileContent.split('\n').reduce((acc: { [key: string]: string }, line: string) => {
          const match = line.match(/(?:[^;"]+|"[^"]*")+/g);
          if (match && match.length) {
            const [key, translation] = match;
            set(acc, key, trim(translation, '"'));
          }
          return acc;
        }, {}),
      ),
    );
  }
}

export class NoopTranslateLoader implements TranslateLoader {
  getTranslation(): Observable<{ [key: string]: string }> {
    return of({});
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { AuthQuery } from '../../routes/auth/auth.query';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly query$ = inject(AuthQuery);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return (this.query$.jwtToken$ as Observable<string>).pipe(
      //FIXME Discuss this fix, which only allows the one request
      take(1),
      filter((token: string) => !!token),
      switchMap((token: string) => {
        const headers = req.headers.set('Content-Type', 'application/json').set('Authorization', token);
        const authReq = req.clone({ headers });
        return next.handle(authReq);
      }),
    );
  }
}

import { Routes } from '@angular/router';

export const AuthRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./auth-host/auth-host.component').then((c) => c.AuthHostComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./auth-login/auth-login.component').then((c) => c.AuthLoginComponent),
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import('./auth-forgot-password/auth-forgot-password.component').then((c) => c.AuthForgotPasswordComponent),
      },
      {
        path: 'reset-password',
        loadComponent: () =>
          import('./auth-reset-password/auth-reset-password.component').then((c) => c.AuthResetPasswordComponent),
      },
      {
        path: 'access-code',
        loadComponent: () =>
          import('./auth-access-code/auth-access-code.component').then((c) => c.AuthAccessCodeComponent),
      },
      {
        path: 'success',
        loadComponent: () => import('./auth-success/auth-success.component').then((c) => c.AuthSuccessComponent),
      },
      {
        path: 'email-sent',
        loadComponent: () =>
          import('./auth-email-sent/auth-email-sent.component').then((c) => c.AuthEmailSentComponent),
      },
    ],
  },
];

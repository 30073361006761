import { Routes } from '@angular/router';
import { AuthRoutes } from '../routes/auth/auth.routes';
import { AUTH_SETTINGS } from '../routes/auth/auth.token';
import { AppConstants } from '../shared/config/app.constants';
import { DevLoadingGuard } from '../shared/guards/dev-loading.guard';
import { FeatureFlagLoadingGuard } from '../shared/guards/feature-flag-loading.guard';
import { authenticatedGuard } from './authenticated.guard';

export const CoreRoutes: Routes = [
  {
    path: 'auth',
    children: AuthRoutes,
    providers: [
      {
        provide: AUTH_SETTINGS,
        useValue: {
          REDIRECT_PATH: AppConstants.AUTH_REDIRECT_PATH,
        },
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadComponent: () => import('./layout/authenticated.component').then((c) => c.AuthenticatedComponent),
        canActivate: [authenticatedGuard()],
        children: [
          {
            path: 'archive',
            loadChildren: () => import('../routes/archive/archive.routes'),
          },
          {
            path: 'artworks',
            loadChildren: () => import('../routes/artworks/artworks.routes'),
          },
          {
            path: 'publications',
            loadChildren: () => import('../routes/publications/publications.routes'),
          },
          {
            path: 'agents',
            loadChildren: () => import('../routes/agents/agents.routes'),
          },
          {
            path: 'auctions',
            loadChildren: () => import('../routes/auctions/auctions.routes'),
          },
          {
            path: 'exhibitions',
            loadChildren: () => import('../routes/exhibitions/exhibitions.routes'),
          },
          {
            path: 'tasks',
            loadChildren: () => import('../routes/task-manager/task-manager.routes'),
          },
          {
            path: 'collection-management',
            loadChildren: () => import('../routes/collection-management/collection-management.routes'),
            canLoad: [FeatureFlagLoadingGuard],
            data: { featureFlag: AppConstants.FeatureFlagEnum.COLLECTION_MANAGEMENT },
          },
          {
            path: 'master-data',
            loadChildren: () => import('../routes/master-data/master-data.routes'),
          },
          {
            path: 'dev',
            loadChildren: () => import('../routes/dev/dev.routes'),
            canLoad: [DevLoadingGuard],
          },
          {
            path: 'reports',
            loadChildren: () => import('../routes/reports/reports.routes'),
          },
          {
            path: '**',
            redirectTo: '/artworks',
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/auth' },
];

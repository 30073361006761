import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { DATA_TABLE_SETTINGS } from '@navigatingart/data-table';
import { FORM_FIELD_MODULE_SETTINGS } from '@navigatingart/form-fields';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { provideToastr } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { AppConstants } from '../shared/config/app.constants';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { MarshallingInterceptor } from '../shared/interceptors/marshalling.interceptor';
import { NoopTranslateLoader, SSVTranslateLoader } from '../shared/ssv-translate-loader';
import { initializeCoreFactory } from './core.init';
import { CoreRoutes } from './core.routes';
import { MaterialProviders } from './providers/material.providers';
import { SentryProviders } from './providers/sentry.providers';

export function provideCore() {
  return [
    provideHttpClient(withInterceptorsFromDi()),
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true,
        },
      },
    }),
    provideToastr({
      positionClass: 'toast-top-right',
    }),
    provideRouter(CoreRoutes),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).Cypress ? provideNoopAnimations() : provideAnimations(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          useClass: (window as any).Cypress ? NoopTranslateLoader : SSVTranslateLoader,
        },
      }),
      environment.production ? [] : AkitaNgDevtools.forRoot(),
    ),
    {
      provide: FORM_FIELD_MODULE_SETTINGS,
      useValue: {
        isProduction: environment.production,
      },
    },
    {
      provide: DATA_TABLE_SETTINGS,
      useValue: {
        AUTOCOMPLETE_DEBOUNCE_TIME: 250,
        DATE_QUERY_FORMAT: AppConstants.DATE_QUERY_FORMAT,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MarshallingInterceptor,
      multi: true,
    },
    environment.sentry?.enabled ? SentryProviders : [],
    ...MaterialProviders,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeCoreFactory,
      deps: [TranslateService],
    },
  ];
}

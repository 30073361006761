import { MAT_LUXON_DATE_ADAPTER_OPTIONS, provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { AppConstants, DATE_PICKER_FORMAT_YYYY_MM_DD } from '../../shared/config/app.constants';

export const MaterialProviders = [
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: { verticalPosition: 'top', horizontalPosition: 'center' },
  },
  {
    provide: MAT_DATE_LOCALE,
    useValue: 'en-GB',
  },
  {
    provide: MAT_RIPPLE_GLOBAL_OPTIONS,
    useValue: {
      disabled: true,
    },
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      // minWidth: '70vw',
      hasBackdrop: true,
    },
  },
  provideLuxonDateAdapter(DATE_PICKER_FORMAT_YYYY_MM_DD),
  { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      showDelay: AppConstants.MAT_TOOLTIP_DELAY,
    },
  },
];

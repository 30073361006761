import { Injectable, inject } from '@angular/core';
import { Route, Router, UrlTree } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DevLoadingGuard {
  private router = inject(Router);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canLoad(route: Route): boolean | UrlTree {
    return !environment.production ? true : this.router.createUrlTree(['/artworks']);
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarshalService } from '../services/marshal.service';

@Injectable()
export class MarshallingInterceptor implements HttpInterceptor {
  private readonly marshalService = inject(MarshalService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const unmarshallingMethods = ['POST', 'PUT'];
    const body = unmarshallingMethods.includes(req.method) ? this.marshalService.unmarshal(req.body) : req.body;
    const marshalReq = req.clone({ body });
    return next
      .handle(marshalReq)
      .pipe(map((ev: HttpEvent<unknown>) => this.marshalService.marshal<HttpEvent<unknown>>(ev)));
  }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, GuardResult, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthQuery } from '../routes/auth/auth.query';
import { AuthService } from '../routes/auth/auth.service';
import { AppConstants } from '../shared/config/app.constants';
import { AuthenticatedService } from './authenticated.service';

export function authenticatedGuard(): CanActivateFn {
  return (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GuardResult> => {
    const router = inject(Router);
    const query = inject(AuthQuery);
    const authService = inject(AuthService);
    const authenticatedService = inject(AuthenticatedService);

    return query.sessionStatus$.pipe(
      switchMap((status) => {
        if (status === 'LOGGEDIN') return of(true);
        if (status === 'EXPIRED') return authService.refreshSession$();
        throw new Error(`Invalid session status ${status}`);
      }),
      switchMap(() => authenticatedService.updateUser()),
      map(() => true),
      catchError(() => {
        localStorage.setItem(AppConstants.AUTH_LOCAL_STORAGE_REDIRECT_URL, state.url);
        return of(router.createUrlTree(['/auth']));
      }),
    );
  };
}
